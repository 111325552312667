<template>
  <div>
    <Navbar />
    <PageTitle pageTitle="Reset Password" />
    <div class="ptb-100">
        <div class="container">
            <div class="row py-5 align items center">
                <div class="col-lg-6 mx-auto">
                    <div class="form-content py-6">
                      <form @submit.prevent="sendResetEmail">
                        <div class="form-group">
                          <label for="user-email">Email Address</label>
                          <input 
                            type="email" 
                            id="user-email" 
                            class="form-control" 
                            v-model="v$.email.$model" 
                            :class="{ 'is-invalid': v$.email.$error }" 
                            placeholder="Enter your email" 
                            required
                          >
                          <div v-if="v$.email.$error" class="invalid-feedback">
                            <div v-if="!v$.email.required.$response">Email is required.</div>
                            <div v-if="!v$.email.email.$response">Please enter a valid email.</div>
                          </div>
                        </div>
                        <button type="submit" class="default-btn">Send Password Reset Email</button>
                        <div v-if="message" class="alert alert-success text-center mt-4 mb-0" role="alert">
                          {{ message }}
                        </div>
                        <div v-if="error" class="alert alert-danger text-center mt-4 mb-0" role="alert">
                          {{ error }}
                        </div>
                      </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Footer />
  </div>
</template>


<script>
import { ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, email as emailValidator } from '@vuelidate/validators';
import { getAuth, sendPasswordResetEmail as firebaseSendPasswordResetEmail } from 'firebase/auth';
import Navbar from '../Layout/Navbar';
import PageTitle from '../Common/PageTitle';
import Footer from '../Layout/Footer';

export default {
  components: {
    Navbar,
    PageTitle,
    Footer
  },
  setup() {
    const email = ref('');
    const message = ref('');
    const error = ref('');

    const rules = {
      email: { required, email: emailValidator },
    };

    const v$ = useVuelidate(rules, { email });

    const sendResetEmail = async () => {
      v$.value.$touch();
      if (v$.value.$invalid) {
        return; // Prevent form submission if validation fails
      }

      try {
        const auth = getAuth();
        await firebaseSendPasswordResetEmail(auth, email.value);
        message.value = 'Password reset email sent. Please check your inbox.';
      } catch (err) {
        console.error(err);
        error.value = 'Failed to send password reset email. Please try again.';
      }
    };

    return {
      email,
      message,
      error,
      v$,
      sendResetEmail
    };
  }
};
</script>
