<template>
  <div>
    <Navbar />
    <PageTitle pageTitle="My Account" />
    <section style="background-color: #eee">
      <div class="container py-5">
        <div class="row">
          <div class="col-lg-4">
            <div class="card mb-4">
              <div class="card-body text-center">
                <img
                  v-if="userData && userData.photoURL"
                  :src="userData.photoURL"
                  alt="avatar"
                  class="rounded-circle img-fluid"
                  style="width: 150px"
                  referrerpolicy="no-referrer"
                />
                <img
                  v-else
                  src="/og.jpg"
                  alt="avatar"
                  class="rounded-circle img-fluid"
                  style="width: 150px"
                />
                <h5 class="my-3" v-if="userData">{{ userData.displayName }}</h5>
                <p class="text-muted mb-2" v-if="userData">
                  {{ userData.email }}
                </p>
                <button
                  type="submit"
                  class="default-btn mr-3"
                  @click.prevent="$router.push('/admin')"
                  style="font-weight: 600"
                  v-if="isAdmin"
                >
                  Admin
                </button>
                <button
                  type="submit"
                  class="default-btn"
                  @click.prevent="handleSignOut"
                  style="font-weight: 600"
                  v-if="userData"
                >
                  Sign Out
                </button>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="card mb-4">
              <div class="card-body">
                <div class="tabs">
                  <!-- tab 1 -->
                  <input
                    class="radiotab"
                    name="tabs"
                    tabindex="1"
                    type="radio"
                    id="tabone"
                    checked="checked"
                  />
                  <label class="label" for="tabone">Profile</label>
                  <div class="panel" tabindex="1">
                    <form @submit.prevent="updateProfile">
                      <div class="row mb-md-4">
                        <div class="col-sm-6 mb-2 mb-md-0">
                          <div class="form-floating">
                            <input
                              type="text"
                              class="form-control"
                              id="floatingInput19"
                              placeholder="Name"
                              :class="{ 'is-invalid': firstNameError }"
                              v-model="firstName"
                            />
                            <label for="floatingInput19">First Name</label>
                            <span class="text-danger" v-if="firstNameError">First Name is a required field</span>
                          </div>
                        </div>
                        <div class="col-sm-6 mb-2 mb-md-0">
                          <div class="form-floating">
                            <input
                              type="text"
                              class="form-control"
                              id="floatingInput20"
                              placeholder="Last Name"
                              :class="{ 'is-invalid': lastNameError }"
                              v-model="lastName"
                            />
                            <label for="floatingInput20">Last Name</label>
                            <span class="text-danger" v-if="lastNameError">Last Name is a required field</span>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-md-4">
                        <div class="col-sm-6 mb-2 mb-md-0">
                          <div class="form-floating">
                            <input
                              type="text"
                              class="form-control"
                              id="floatingInput3"
                              placeholder="Phone"
                              :class="{ 'is-invalid': phoneError }"
                              v-model="phone"
                            />
                            <label for="floatingInput3">Contact Phone</label>
                            <span class="text-danger" v-if="phoneError">{{
                              phoneError
                            }}</span>
                          </div>
                        </div>
                        <div class="col-sm-6 mb-2 mb-md-0">
                          <div class="form-floating">
                            <input
                              type="text"
                              class="form-control"
                              id="floatingInput5"
                              placeholder="Company"
                              :class="{ 'is-invalid': companyError }"
                              v-model="company"
                            />
                            <label for="floatingInput5">Company</label>
                            <span class="text-danger" v-if="companyError">{{
                              companyError
                            }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-6 mb-2 mb-md-0">
                          <div class="form-group">
                            <select
                              name="role"
                              v-model="role"
                              id="role"
                              class="form-control"
                              placeholder="Role"
                              :class="{ 'is-invalid': roleError }"
                              required
                            >
                              <option value="" selected disabled>
                                Select Your Role
                              </option>
                              <option value="Developer">Developer</option>
                              <option value="Project Manager">
                                Project Manager
                              </option>
                              <option value="Manufacturer">Manufacturer</option>
                              <option value="Wholesaler">Wholesaler</option>
                              <option value="System Integrator">
                                System Integrator
                              </option>
                              <option value="Consultant">Consultant</option>
                              <option value="Contractor">Contractor</option>
                              <option value="Hotel Operator">
                                Hotel Operator
                              </option>
                              <option value="Other">Other</option>
                            </select>
                            <span class="text-danger" v-if="roleError">{{
                              roleError
                            }}</span>
                          </div>
                        </div>
                        <div class="col-sm-6 mb-2 mb-md-0">
                          <div class="form-group">
                            <select
                              class="form-control"
                              id="floatingInput55"
                              :class="{ 'is-invalid': countryError }"
                              v-model="country"
                            >
                              <option value="">Select your Country</option>
                              <option
                                v-for="country in countryList"
                                :key="country.code"
                                :value="country.code"
                              >
                                {{ country.name }}
                              </option>
                            </select>
                            <span class="text-danger" v-if="countryError">{{
                              countryError
                            }}</span>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div class="row">
                        <div
                          class="col-md-12 text-right d-flex flex-row-reverse"
                        >
                          <button
                            type="submit"
                            class="default-btn"
                            :disabled="isFormValid"
                          >
                            Update Profile
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <!-- tab 2 -->
                  <input
                    class="radiotab"
                    tabindex="1"
                    name="tabs"
                    type="radio"
                    id="tabtwo"
                  />
                  <label class="label" for="tabtwo">Projects</label>
                  <div class="panel" tabindex="1">
                    <UserProjects />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import Swal from "sweetalert2";
import Navbar from "../Layout/Navbar";
import UserProjects from "./UserProjects.vue";
import PageTitle from "../Common/PageTitle";
import Footer from "../Layout/Footer";
import { ref, onMounted, computed } from "vue";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
} from "firebase/firestore";
import { useRoute, useRouter } from "vue-router";
import { useForm, useField } from "vee-validate";
import * as yup from "yup"; // yup is used for validation schema

export default {

  setup() {
    const userData = ref("");
    const db = getFirestore();
    const auth = getAuth();
    const route = useRoute();
    const verifiedEmail = ref(route.query.verifiedEmail);
    const router = useRouter();
    const isAdmin = ref(false); // Add this line
    const countryList = ref([]);

    const schema = yup.object({
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      phone: yup
        .string()
        .required()
        .matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, "Phone number must be valid"),
      company: yup.string().required(),
      country: yup.string().required(),
      role: yup.string().required(),
    });

    const { handleSubmit, meta } = useForm({
      validationSchema: schema,
    });

    const { value: firstName, errorMessage: firstNameError } =
      useField("firstName");
    const { value: lastName, errorMessage: lastNameError } =
      useField("lastName");
    const { value: phone, errorMessage: phoneError } = useField("phone");
    const { value: company, errorMessage: companyError } = useField("company");
    const { value: country, errorMessage: countryError } = useField("country");
    const { value: role, errorMessage: roleError } = useField("role");

    const isFormValid = computed(() => {
      return !meta.value.valid; // Check if form is not valid
    });

    const loadCountries = async () => {
      try {
        const response = await fetch("/countries.json"); // Adjust path if necessary
        if (!response.ok) {
          throw new Error("Failed to fetch countries");
        }
        countryList.value = await response.json();
      } catch (error) {
        console.error("Error loading countries:", error);
      }
    };

    const fetchUserData = async (userId) => {
      const docRef = doc(db, "users", userId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        isAdmin.value = data.role === "admin"; // Set isAdmin based on user's role
        firstName.value = data.firstName || ""; // Set phone value if it exists
        lastName.value = data.lastName || ""; // Set phone value if it exists
        phone.value = data.phone || ""; // Set phone value if it exists
        company.value = data.company || "";
        country.value = data.country || "";
        role.value = data.role || "";
        // Handle other data fields similarly
      } else {
        console.log("No such document!");
      }
    };

    onMounted(() => {
      loadCountries();
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          userData.value = user;
          await fetchUserData(user.uid);
          // await fetchProjectsForUser(user.uid); // Fetch projects for this user
        } else {
          userData.value = null;
        }
      });
      if (verifiedEmail.value === "true") {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your email has been verified successfully!",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    });

    const updateProfile = handleSubmit(async (values) => {
      const currentUser = getAuth().currentUser;
      if (currentUser) {
        try {
          await setDoc(
            doc(db, "users", currentUser.uid),
            {
              firstName: values.firstName,
              lastName: values.lastName,
              phone: values.phone,
              company: values.company,
              country: values.country,
              role: values.role,
              // add other fields as needed
            },
            { merge: true }
          );
          // Show success message or redirect
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your profile has been saved",
            showConfirmButton: false,
            timer: 1500,
          });
        } catch (error) {
          console.error("Error updating user profile: ", error);
        }
      } else {
        // User is not logged in, redirect to login page or show error
        router.push("/login");
      }
    });

    const handleSignOut = async () => {
      signOut(auth).then(() => {
        router.push("/");
      });
    };

    return {
      userData,
      firstName,
      lastName,
      phone,
      company,
      country,
      role,
      isAdmin,
      updateProfile,
      handleSignOut,
      countryList,
      firstNameError,
      lastNameError,
      phoneError,
      companyError,
      countryError,
      roleError,
      isFormValid,
      handleSubmit,
    };
  },
  components: {
    UserProjects,
    Navbar,
    PageTitle,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto 20px;
  border-radius: 10px 10px 0 0;
}

.radiotab {
  position: absolute;
  opacity: 0;
}

.default-btn {
  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
}

.label {
  width: 100%;
  padding: 22px 20px;
  background: #fff;
  cursor: pointer;
  font-weight: bold;
  font-size: 23px;
  color: #000;
  transition: background 0.3s, color 0.3s;
  border: none;
  border-radius: 0;
  text-align: center;
}

.label:hover {
  background: #ee644f;
  color: #fff;
}

.label:active {
  background: #fff;
}

.radiotab:checked + .label {
  background: #ef3f23;
  color: #fff;
}

.panel {
  padding-top: 40px;
  padding-left: 20px;
  padding-right: 20px;
  display: none;
  background: #fff;
  width: 100%;
}
.radiotab:checked + .label + .panel {
  display: block;
}

@media (min-width: 600px) {
  .panel {
    order: 99;
  }

  .label {
    width: 50%;
    border-radius: 10px 10px 0 0;
    border-bottom: solid 2px #ccc;
  }
}
@media (max-width: 599px) {
  .panel {
    order: 99;
  }

  .label {
    width: 49%;
    border-radius: 10px 10px 0 0;
    border-bottom: solid 2px #ccc;
    font-size: 17px;
  }
}
</style>
