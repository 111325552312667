<template>
  <div>
      <Navbar />
      <PageTitle pageTitle="Admin Panel" />
      <div class="text-center my-5 px-4">
        111
      </div>
      <div class="text-center mb-4">
        <div class="container my-4">
          <h3>Users</h3>
          <ul class="list-group">
            <li v-for="user in users" :key="user.id" class="list-group-item">
              {{ user.name }} ({{ user.email }})
              <button class="btn btn-primary btn-sm" @click="viewUserProjects(user)">View Projects</button>
            </li>
          </ul>
        </div>
        <div class="container my-4">
          <h3>Projects</h3>
          <button class="btn btn-success mb-3" @click="openProjectCreationModal">Create New Project</button>
          <div v-if="selectedUserProjects.length">
            <h5>Projects for {{ selectedUser.name }}:</h5>
            <ul class="list-group">
              <li v-for="project in selectedUserProjects" :key="project.id" class="list-group-item">
                {{ project.name }}
                <!-- Add buttons or links for editing, deleting, managing files -->
              </li>
            </ul>
          </div>
          <div v-else>
            <p>Select a user to view their projects.</p>
          </div>
        </div>
        <router-link to="/" class="default-btn mr-3">
            Back to Home
        </router-link>
      </div>
      <Footer />
  </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import Footer from '../Layout/Footer'
import { ref, onMounted } from 'vue';
import { getFirestore, collection, getDocs } from 'firebase/firestore';

export default {
  components: {
      Navbar,
      PageTitle,
      Footer,
  },
  setup() {
    const db = getFirestore(); // Initialize Firestore
    const users = ref([]); // Array of users
    const selectedUser = ref({}); // Currently selected user
    const selectedUserProjects = ref([]); // Projects for the selected user

    const fetchUsers = async () => {
      const querySnapshot = await getDocs(collection(db, "users"));
      users.value = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    };

    onMounted(() => {
      fetchUsers();
    });

    const viewUserProjects = (user) => {
      selectedUser.value = user;
      // Fetch projects for the selected user
    };

    const openProjectCreationModal = () => {
      // Open a modal or navigate to a form to create a new project
    };

    // Add methods for creating, editing, deleting projects and managing files

    return {
      users,
      selectedUser,
      selectedUserProjects,
      viewUserProjects,
      openProjectCreationModal,
    };
  },
}
</script>
