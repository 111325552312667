<template>
    <div class="ptb-100">
        <div class="container">
            <div class="row align items center">
                <div class="col-lg-6 mx-auto">
                    <div class="form-content">
                        <form>
                            <div class="form-group">
                                <label for="your-email">Email</label>
                                <input 
                                    type="email" 
                                    class="form-control" 
                                    id="your-email" 
                                    v-model="v$.email.$model" 
                                    :class="{ 'is-invalid': v$.email.$error }" 
                                    placeholder="Your email"
                                >
                                <div v-if="v$.email.$error" class="invalid-feedback">
                                    <div v-if="!v$.email.required.$response">Email is required.</div>
                                    <div v-if="!v$.email.email.$response">Please enter a valid email.</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="your-password">Password</label>
                                <input 
                                    type="password" 
                                    class="form-control" 
                                    id="your-password" 
                                    v-model="v$.password.$model" 
                                    :class="{ 'is-invalid': v$.password.$error }" 
                                    placeholder="Your password"
                                >
                                <div v-if="v$.password.$error" class="invalid-feedback">
                                    <div v-if="!v$.password.required.$response">Password is required.</div>
                                </div>
                            </div>
                            <p v-if="errorMsg">{{ errorMsg }}</p>
                            <button type="submit" class="default-btn mb-4" @click.prevent="signin">Sign In</button>
                            <button type="submit" class="default-btn" @click.prevent="signInWithGoogle">Sign In with Google</button>
                        </form>

                        <div class="form-footer mb-0">
                            <p class="mb-2">Forgot your password? <router-link to="/password-reset">Reset it here</router-link></p>
                            <p>Don’t have an account? <router-link class="form-link" to="/register">Signup</router-link></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import { getAuth, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';
import { useRouter } from 'vue-router';
import { useVuelidate } from '@vuelidate/core';
import { required, email as emailValidator } from '@vuelidate/validators';

    export default {
        name: 'LoginContent',
        setup() {
        const email = ref('');
        const password = ref('');
        const errorMsg = ref('');
        const router = useRouter();

        const rules = {
            email: { required, email: emailValidator },
            password: { required },
        };

        const v$ = useVuelidate(rules, { email, password });

        const signin = async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                return; // Prevent form submission if validation fails
            }
            try {
                await signInWithEmailAndPassword(getAuth(), email.value, password.value);
                router.push('/account');
            } catch (error) {
                console.error(error);
                // Implement user-friendly error handling here
                switch (error.code) {
                    case 'auth/invalid-email':
                        errorMsg.value = "Invalid Email";
                        break;
                    case 'auth/user-not-found':
                        errorMsg.value = "User not found for the provided email address.";
                        break;
                    case 'auth/wrong-password':
                        errorMsg.value = "Wrong Password";
                        break;
                    default:
                        errorMsg.value = "Email or password was incorrect";
                        break;
                }
            }
        }

        const signInWithGoogle = async () => {
            const provider = new GoogleAuthProvider();
            const auth = getAuth();
            try {
                const result = await signInWithPopup(auth, provider);
                const user = result.user;
                const userDocRef = doc(getFirestore(), "users", user.uid);
                const userDocSnap = await getDoc(userDocRef);

                if (!userDocSnap.exists()) {
                    // If the user document does not exist, create a new one
                    await setDoc(userDocRef, {
                        email: user.email,
                        // other fields can be initialized empty or with default values
                    });
                } else {
                    // The document already exists, you might want to update it or just proceed
                }

                router.push('/account');
            } catch (error) {
                console.error("Error with Google sign-in: ", error);
                // Handle the error appropriately
            }
        };

        return {
            email,
            password,
            v$,
            errorMsg,
            signin,
            signInWithGoogle,
        };
    }
    }
</script>