<template>
  <div>
    <div class="add-project-form">
      <h3>Add New Project</h3>
      <form @submit.prevent="validateAndSubmit">
        <div class="form-group mb-3">
          <label for="projectName">Project Name:</label>
          <input
            type="text"
            id="projectName"
            v-model.trim="newProjectName"
            class="form-control"
            :class="{ 'is-invalid': !newProjectNameValid }"
            required
          />
          <span v-if="!newProjectNameValid" class="text-danger">Project Name is required</span>
        </div>
        <div class="form-group mb-3">
          <label for="projectDescription">Description:</label>
          <textarea
            id="projectDescription"
            v-model.trim="newProjectDescription"
            class="form-control"
            :class="{ 'is-invalid': !newProjectDescriptionValid }"
            required
          ></textarea>
          <span v-if="!newProjectDescriptionValid" class="text-danger">Description is required</span>
        </div>
        <div class="row">
          <div class="col-12 col-sm-6">
            <div class="form-group mb-3">
              <label for="serialNumber">Serial Number:</label>
              <input
                type="text"
                id="serialNumber"
                v-model.trim="serialNumber"
                class="form-control"
                :class="{ 'is-invalid': !serialNumberValid }"
                required
              />
              <span v-if="!serialNumberValid" class="text-danger">Serial Number is required</span>
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label for="amountOfRooms">Amount of Rooms:</label>
              <input
                type="number"
                id="amountOfRooms"
                v-model.number="amountOfRooms"
                min="10"
                max="90"
                class="form-control"
                :class="{ 'is-invalid': !amountOfRoomsValid }"
                required
              />
              <span v-if="!amountOfRoomsValid" class="text-danger">Amount of Rooms is required and must be between 10 and 90</span>
            </div>
          </div>
        </div>
        <button type="submit" class="btn default-btn mt-4" :disabled="submitButtonDisabled">
          Create Project
        </button>
      </form>
    </div>
    <div
      v-for="project in userProjects"
      :key="project.id"
      class="card mb-4 mt-5"
    >
      <div class="card-body">
        <h5>Name: {{ project.projectName }}</h5>
        <p>Description: {{ project.description }}</p>
        <p>SERIAL NUMBER:{{ project.serialNumber }}</p>
        <p>Rooms:{{ project.amountOfRooms }}</p>
        <ul>
          <li v-for="(file, index) in project.files" :key="index">
            <a :href="file.fileURL" target="_blank">{{ file.fileName }}</a>
            ({{ file.fileType }})
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { ref, computed } from "vue";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  getFirestore,
  doc,
  setDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";

export default {
  setup() {
    const auth = getAuth();
    const newProjectName = ref("");
    const serialNumber = ref("");
    const amountOfRooms = ref(10);
    const newProjectDescription = ref("");
    const userProjects = ref([]);

    const validateInputs = () => {
      return (
        newProjectName.value.trim() !== "" &&
        newProjectDescription.value.trim() !== "" &&
        serialNumber.value.trim() !== "" &&
        amountOfRooms.value >= 10 &&
        amountOfRooms.value <= 90
      );
    };

    const resetForm = () => {
      newProjectName.value = "";
      serialNumber.value = "";
      amountOfRooms.value = 10;
      newProjectDescription.value = "";
    };

    const validateAndSubmit = async () => {
      if (!validateInputs()) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please fill all the required fields correctly.",
          showConfirmButton: false,
          timer: 3000,
        });
        return;
      }

      if (!auth.currentUser) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "You must be logged in to create projects.",
          showConfirmButton: false,
          timer: 3000,
        });
        return;
      }

      // Check if the user has already created 20 projects
      if (userProjects.value.length >= 20) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "You have reached the maximum limit of 20 projects.",
          showConfirmButton: false,
          timer: 3000,
        });
        return;
      }

      const projectData = {
        projectName: newProjectName.value,
        serialNumber: serialNumber.value,
        amountOfRooms: amountOfRooms.value,
        description: newProjectDescription.value,
        userId: auth.currentUser.uid,
        userEmail: auth.currentUser.email,
        timestamp: new Date(),
      };

      try {
        const db = getFirestore();
        await setDoc(doc(collection(db, "projects")), projectData);
        userProjects.value.push({ ...projectData, id: doc.id });
        resetForm(); // Reset the form
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Project added successfully!",
          showConfirmButton: false,
          timer: 3000,
        });
      } catch (error) {
        console.error("Error adding project: ", error);
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Failed to add project.",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    };

    const newProjectNameValid = computed(() => newProjectName.value.trim() !== "");
    const newProjectDescriptionValid = computed(() => newProjectDescription.value.trim() !== "");
    const serialNumberValid = computed(() => serialNumber.value.trim() !== "");
    const amountOfRoomsValid = computed(() => amountOfRooms.value >= 10 && amountOfRooms.value <= 90);

    const submitButtonDisabled = computed(() => {
      return !newProjectNameValid.value || !newProjectDescriptionValid.value || !serialNumberValid.value || !amountOfRoomsValid.value;
    });

    const fetchProjectsForUser = async (userId) => {
      const db = getFirestore();
      const projectsQuery = query(
        collection(db, "projects"),
        where("userId", "==", userId)
      );
      try {
        const querySnapshot = await getDocs(projectsQuery);
        userProjects.value = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
      } catch (error) {
        console.error("Error fetching projects: ", error);
      }
    };

    onAuthStateChanged(auth, async (user) => {
      if (user) {
        await fetchProjectsForUser(user.uid);
      }
    });

    return {
      newProjectName,
      serialNumber,
      amountOfRooms,
      newProjectDescription,
      validateAndSubmit,
      userProjects,
      newProjectNameValid,
      newProjectDescriptionValid,
      serialNumberValid,
      amountOfRoomsValid,
      submitButtonDisabled,
    };
  },
};
</script>
