<template>
<div class="ptb-100">
    <div class="container">
    <div class="row align-items-center">
        <div class="col-lg-6 mx-auto">
        <div class="form-content">
            <form class="needs-validation" novalidate>
            <div class="form-group">
                <label for="email">Email</label>
                <input 
                type="email" 
                class="form-control" 
                id="email" 
                v-model="v$.email.$model" 
                :class="{ 'is-invalid': v$.email.$error }"
                placeholder="Your email" 
                required
                >
                <div v-if="v$.email.$error" class="invalid-feedback">
                <div v-if="!v$.email.required.$response">Email is required.</div>
                <div v-if="!v$.email.email.$response">Please enter a valid email.</div>
                </div>
            </div>
            <div class="form-group">
                <label for="password">Password</label>
                <input 
                type="password" 
                class="form-control" 
                id="password" 
                v-model="v$.password.$model" 
                :class="{ 'is-invalid': v$.password.$error }"
                placeholder="Your password" 
                required
                >
                <div v-if="v$.password.$error" class="invalid-feedback">
                <div v-if="!v$.password.required.$response">Password is required.</div>
                <div v-if="!v$.password.minLength.$response">Password must be at least 6 characters long.</div>
                </div>
            </div>
            <div v-if="errorMsg" class="alert alert-danger mb-0 mb-4" role="alert">
                {{ errorMsg }}
            </div>
            <button type="submit" class="default-btn mb-4" @click.prevent="register">Sign up</button>
            <button type="submit" class="default-btn" @click.prevent="signInWithGoogle">Sign Up with Google</button>
            </form>

            <div class="form-footer">
            <p>Do you already have an account? <router-link class="form-link" to="/login">Login</router-link></p>
            </div>
        </div>
        </div>
    </div>
    </div>
</div>
</template>
<script>
import { ref, onMounted } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, email as emailValidator, minLength } from '@vuelidate/validators';
import { getAuth, createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, sendEmailVerification } from 'firebase/auth';
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';
import { useRouter } from 'vue-router';
// import Swal from 'sweetalert2'

export default {
    name: 'SignupContent',
    setup() {
        const email = ref('');
        const password = ref('');
        const errorMsg = ref('');
        const router = useRouter();
        const db = getFirestore();

        const rules = {
            email: { required, email: emailValidator },
            password: { required, minLength: minLength(6) },
        };

        const v$ = useVuelidate(rules, { email, password });

        const register = async () => {
            v$.value.$touch();

            if (v$.value.$invalid) {
                return; // Prevent form submission if validation fails
            }

            try {
                const auth = getAuth();
                const data = await createUserWithEmailAndPassword(auth, email.value, password.value);
                console.log("Successfully Registered!", data);
                const user = data.user;

                // Send verification email
                await sendEmailVerification(user);

                await setDoc(doc(db, "users", user.uid), {
                    email: email.value,
                    // other fields
                });

                // Update the redirect or provide a message
                // For example, redirect to a verification notice page
                router.push({ path: '/verify-email', query: { newRegistration: 'true' } });
            } catch (error) {
                console.error(error);
                // Implement user-friendly error handling here
                switch (error.code) {
                    case 'auth/email-already-in-use':
                        errorMsg.value = "This email address is already in use by another account.";
                        break;
                    case 'auth/invalid-email':
                        errorMsg.value = "The email address is badly formatted.";
                        break;
                    case 'auth/operation-not-allowed':
                        errorMsg.value = "Email/password accounts are not enabled. Please contact support.";
                        break;
                    case 'auth/weak-password':
                        errorMsg.value = "The password is too weak. Please choose a stronger password.";
                        break;
                    default:
                        errorMsg.value = "An unknown error occurred. Please try again.";
                        break;
                }
            }
        }

        const signInWithGoogle = async () => {
            const provider = new GoogleAuthProvider();
            const auth = getAuth();
            try {
                const result = await signInWithPopup(auth, provider);
                const user = result.user;
                const userDocRef = doc(getFirestore(), "users", user.uid);
                const userDocSnap = await getDoc(userDocRef);

                if (!userDocSnap.exists()) {
                    // If the user document does not exist, create a new one
                    await setDoc(userDocRef, {
                        email: user.email,
                        // other fields can be initialized empty or with default values
                    });
                } else {
                    // The document already exists, you might want to update it or just proceed
                }

                router.push('/account');
            } catch (error) {
                console.error("Error with Google sign-in: ", error);
                // Handle the error appropriately
            }
        };

        // If needed, make sure Firebase is initialized before the component mounts
        onMounted(() => {
            // Initialize Firebase Auth if not already done
        });

        return {
            email,
            password,
            v$,
            errorMsg,
            register,
            signInWithGoogle
        };
    }
}
</script>