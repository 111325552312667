<template>
  <div>
    <Navbar />
    <PageTitle pageTitle="Verify Email" />
    <div class="text-center px-4">
      <div class="ptb-100">
        <div class="container">
            <div class="row py-5 align items center">
                <div class="col-lg-6 mx-auto">
                    <div class="form-content py-6">
                      <form @submit.prevent="resetPassword" v-if="showResetPasswordForm" class="mb-4">
                        <div class="form-group">
                          <label for="new-password">New Password</label>
                          <input 
                            type="password" 
                            id="new-password" 
                            class="form-control" 
                            v-model="v$.newPassword.$model" 
                            :class="{ 'is-invalid': v$.newPassword.$error }" 
                            placeholder="Enter new password" 
                            required
                          >
                          <div v-if="v$.newPassword.$error" class="invalid-feedback">
                            <div v-if="!v$.newPassword.required.$response">New password is required.</div>
                            <div v-if="!v$.newPassword.minLength.$response">New password must be at least 6 characters long.</div>
                          </div>
                        </div>
                        <button type="submit" class="default-btn">Reset Password</button>
                      </form>
                      <p v-if="verifying">Processing...</p>
                      <div v-if="verifying" class="alert alert-primary mb-0" role="alert">
                        Processing...
                      </div>
                      <div v-if="verified" class="alert alert-success mb-0" role="alert">
                        Your email has been verified successfully!
                      </div>
                      <div v-if="resetSuccessful" class="alert alert-success mb-0" role="alert">
                        Your password has been reset successfully!
                      </div>
                      <div v-if="error" class="alert alert-danger mb-0" role="alert">
                        {{ errorMessage }}
                      </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import { getAuth, applyActionCode, verifyPasswordResetCode, confirmPasswordReset } from 'firebase/auth';
import { ref, onMounted } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';
import Navbar from '../Layout/Navbar';
import PageTitle from '../Common/PageTitle';
import Footer from '../Layout/Footer';
import { useRouter } from 'vue-router';

export default {
  name: 'VerifyEmail',
  components: {
    Navbar,
    PageTitle,
    Footer,
  },
  setup() {
    const verifying = ref(true);
    const verified = ref(false);
    const resetSuccessful = ref(false);
    const error = ref(false);
    const errorMessage = ref('');
    const router = useRouter();
    const newRegistration = ref(router.currentRoute.value.query.newRegistration === 'true');
    const mode = ref('');
    const oobCode = ref('');
    const newPassword = ref(''); // New state for the new password
    const auth = getAuth();
    const showResetPasswordForm = ref(false);

    const rules = {
      newPassword: { required, minLength: minLength(6) },
    };

    const v$ = useVuelidate(rules, { newPassword });

    onMounted(async () => {
      const queryParams = new URLSearchParams(window.location.search);
      mode.value = queryParams.get('mode');
      oobCode.value = queryParams.get('oobCode');

      if (!oobCode.value && !newRegistration.value) {
        // Handle missing or invalid verification link
        error.value = true;
        errorMessage.value = 'Invalid or missing verification link.';
        verifying.value = false;
        return; // Early return to avoid further processing
      }

      if (mode.value === 'resetPassword') {
        // Show the password reset form
        resetSuccessful.value = false; // Ensure this is reset
        showResetPasswordForm.value = true;
        verifying.value = false;
      } else if (mode.value === 'verifyEmail') {
        try {
          // Handle email verification
          await applyActionCode(auth, oobCode.value);
          verified.value = true;
          if (auth.currentUser) {
            await auth.currentUser.reload();
          }
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Account Verification Successful",
            showConfirmButton: false,
            timer: 1000
          }).then(() => {
            router.push({ path: '/account', query: { verifiedEmail: 'true' } });
          });
        } catch (err) {
          error.value = true;
          errorMessage.value = err.message;
        }
        verifying.value = false;
      } else if (newRegistration.value) {
        // Handle new registration
        errorMessage.value = 'Please check your email to verify your account.';
        error.value = true;
        verifying.value = false;
      }
    });

    const resetPassword = async () => {
      v$.value.$touch();

      if (v$.value.$invalid) {
        return; // Prevent form submission if validation fails
      }
      try {
        await verifyPasswordResetCode(auth, oobCode.value);
        await confirmPasswordReset(auth, oobCode.value, newPassword.value);
        resetSuccessful.value = true;
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Password Reset Successful",
          showConfirmButton: false,
          timer: 2000
        }).then(() => {
          router.push('/login');
        });
      } catch (err) {
        error.value = true;
        errorMessage.value = err.message;
      }
    };

    return { verifying, verified, resetSuccessful, error, errorMessage, newPassword, resetPassword, showResetPasswordForm, v$ };
  }
};
</script>
